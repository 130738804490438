import React from 'react';
import advocateProfilePic from '../../../assets/images/advocates-details/divyaa.jpg'; // Add the correct image path

const AdvocateManjuDetails = () => {
  return (
    <section className='team-details'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-lg-5'>
            <div className='team-details__content'>
              <h2 className='team-details__title'>Read my story</h2>
              <p className='team-details__text'>
                Ms. Manju completed her B.A. LLB from Damodaram Sanjivayya
                National Law University, Visakhapatnam in the year 2024. She is
                interested in the areas of Civil laws, Arbitration and Family
                Laws. She joined Avadhani and Associates as a legal associate.
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={advocateProfilePic} alt='Ms. Manju Molakalapalli' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>Ms. Manju Molakalapalli</h2>
                <p className='team-one__designation'>Legal Associate</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvocateManjuDetails;
